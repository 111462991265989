
.el-int{
    margin-left: 100px;
    width: 260px;
}
    .favorite-content{
        display: flex;
        flex-direction: column;
        height: 100%;
        .favorite-feature{
            display: flex;
            justify-content: space-between;
            margin: 0 20px 0;
            padding-bottom: 20px;
            border-bottom: 1px solid #eaeaea;
            .feature-left{
                .el-select {
                    margin-left: 10px;
                }
            }
        }
        .feature-video-sort{
            display: flex;
            align-items: center;
            padding: 0 0 20px 20px;
            border-bottom: 1px solid #eaeaea;
            .sort-list {
                display: flex;
                .item {
                    padding: 0 10px;
                    height: 26px;
                    line-height: 26px;
                    color: #666;
                    cursor: pointer;
                    &.current,&:hover{
                        background: #2338E6;
                        color: #fff;
                    }
                }
            }
            .sort-name {
                /*line-height: 26px;*/
            }
        }
        .favorite-table{
            display: flex;
            flex-direction: column;
            height: 1%;
            flex: 1;
            ::v-deep.el-table__body-wrapper{
                min-height: 668px;
            }
            .goods-sort {
                font-size: 18px;
                font-weight: bold;
                color: #333;
                display: flex;
                align-items: baseline;
                justify-content: center;
                .sort {
                    text-align: right;
                }
                .exponential-trend {
                    font-size: 12px;
                    font-weight: normal;
                    margin-left: 5px;
                    min-width: 40px;
                    i {
                        font-size: 12px;
                    }
                }
                .rise-red {
                    color: #FF0000;
                }
                .decline-green {
                    color: #20C997;
                }
            }
            .goods-video-detail {
                display: flex;
                align-items: center;
                .goods-img {
                    width: 100px;
                    height: 130px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    img {
                        max-width: 100%;
                        max-height: 100%;
                    }
                }
            }
            .goods-info {
                width: 1%;
                flex: 1;
                margin-left: 15px;
                line-height: 1;
                .goods-link{
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    width: 100%;
                    display: block;
                    color: #333;
                    &:hover {
                        color: #0824CE;
                    }
                }
                .related-info {
                    width: 100%;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    margin-top: 10px;
                    .related-link {
                        color: #E6A23C;
                        &:hover{
                            color: #0824CE;
                        }
                    }
                }
                .user-detail {
                    display: block;
                    width: 100%;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }
                .fans-num {
                    display: block;
                    color: #999;
                    margin-top: 6px;
                }
            }
            .goods-detail {
                display: flex;
                align-items: center;
                cursor: pointer;
                .goods-img {
                    width: 44px;
                    height: 44px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    img {
                        max-width: 100%;
                        max-height: 100%;
                    }
                }
            }
            .borad-name {
                color: #2338E6;
                display: block;
            }
            ::v-deep.el-table__expanded-cell {
                padding: 0;
            }
            ::v-deep.el-table__expanded-cell .el-table th,
            ::v-deep.el-table__expanded-cell .el-table tr {
                background-color: #f3f3f6;
            }
          }
            ::v-deep.el-table--border::after, ::v-deep .el-table--group::after,  ::v-deep.el-table::before {
                background-color: transparent;
            }
            ::v-deep.el-table td{
                padding:7px 0;
            }
            ::v-deep.el-table td {
                border-bottom: 8px solid #ebeef5;
            }
            ::v-deep.el-table th{
                padding: 14px 0 ;
            }
        }

